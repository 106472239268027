import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css';

// Component imports
import Navbar from './components/Navbar';
import Home from './components/Home';
import Contact from './components/Contact';

function App() {

  useEffect(() => {
    TagManager.initialize({gtmId:'G-RCT3H14E6B'});
  },[]);

  return (
    <BrowserRouter>
      <Navbar/>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;