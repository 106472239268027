// General imports
import React from "react";
import './MarqueeOne.css';

const MarqueeOne = () => {
    return (

        <div id="marqueeOneText">
            <p>Full website coming soon!</p>
        </div>

    )
}

export default MarqueeOne;