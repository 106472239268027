// general imports
import React from "react";
import { Link } from "react-router-dom";
import './Menu.css';


const Menu = (hamburgerstatus,sethamburgerstatus) => {

    return (

        <>
        
        {/* navigation menu */}
        <div className="burgerMenu">
            <div className="burgerLinks">
                <Link to="/" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Home</Link>
                {/* <Link to="/map" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Map</Link> */}
                <Link to="/contact" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Contact</Link>
            </div>
        </div>
        
        </>


    )
}

export default Menu;