// General imports
import React from "react";
import './MarqueeThree.css';

const MarqueeThree = () => {
    return (

        <div id="MarqueeThreeText">
            <p>Visit our YouTube page...Chicago Riverwalk Live!</p>
        </div>

    )
}

export default MarqueeThree;