// General imports
import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import Carousel from "react-bootstrap/Carousel";
import './CarouselReact.css';

// component imports
import ReactPlayer from "react-player";
import MarqueeOne from './MarqueeOne';
import MarqueeTwo from './MarqueeTwo';
import MarqueeThree from './MarqueeThree';
import MarqueeFour from './MarqueeFour';

// image imports
import logoHomepage from './img/CRLLogoBW.png';
import navbarLogoHomepage from './img/CRLLogoBW.png';

const CarouselReact = () => {

    // Delay for fadeOut animation
    function fadeOutDelay() {
        setTimeout(fadeOutLogo, 3000);
    }

    // fadeOut animation for logo, subheader (& remove overlay)
    function fadeOutLogo() {
        var fadeTarget = document.getElementById("riverwalkLogoHomepage");
        var fadeTargetTwo = document.getElementById("homepageSubheader");
        var fadeTargetRemove = document.getElementById("homePageOverlay");
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
                fadeTargetTwo.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
                fadeTargetTwo.style.opacity -= 0.1;
            } else {
                fadeTargetRemove.remove();
                clearInterval(fadeEffect);
            }
        }, 150);
    }

    const [marqueeOneState, setMarqueeOneState] = useState(true);
    const [marqueeTwoState, setMarqueeTwoState] = useState(false);

    function handleChangeMarquee() {
        setMarqueeOneState(!marqueeOneState);
        setMarqueeTwoState(!marqueeTwoState);
    }

    return (

        <>

            <div id="marqueeContainer">
                {marqueeOneState && (
                    <>
                        <div id="tickerContainer">
                            <Marquee gradientWidth="20">
                                <MarqueeOne />
                            </Marquee>
                        </div>
                        <div id="tickerContainerTwo">
                            <Marquee gradientWidth="20">
                                <MarqueeTwo />
                            </Marquee>
                        </div>
                    </>
                )}
                {marqueeTwoState && (
                    <>
                        <div id="tickerContainerThree">
                            <Marquee id="marqueeThree" gradientWidth="20">
                                <MarqueeThree />
                            </Marquee>
                        </div>
                        <div id="tickerContainerFour">
                            <Marquee id="marqueeFour" gradientWidth="20">
                                <MarqueeFour />
                            </Marquee>
                        </div>
                    </>
                )}
            </div>

            {/* Carousel */}
            <Carousel fade onSelect={handleChangeMarquee}>
                <Carousel.Item interval={10000} className="carouselItem">
                    <ReactPlayer
                        url='https://www.youtube.com/watch?v=l8iKqLQn9iI'
                        className='reactPlayerHomepage'
                        playing={true}
                        muted={true}
                        height="100%"
                        width="100%">
                    </ReactPlayer>
                    <div id="homepageNavContainer">
                        <img src={navbarLogoHomepage} alt="Chicago Riverwalk Live Logo" id="navbarLogoHomepage" />
                        <p id="riverwalkTextHomepage">chicagoriverwalklive.com</p>
                    </div>
                    <div id="homePageOverlay" onLoad={fadeOutDelay}></div>
                    <img src={logoHomepage} alt="Chicago Riverwalk Live Logo" id="riverwalkLogoHomepage" onLoad={fadeOutDelay} />
                    <div id="homepageSubheader" onLoad={fadeOutDelay}>
                        <p>chicagoriverwalklive.com</p>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={10000} className="carouselItem">
                    <ReactPlayer url='https://www.youtube.com/watch?v=uGgktRAtwgI'
                        className='reactPlayerHomepage'
                        playing={true}
                        muted={true}
                        height="100%"
                        width="100%">
                    </ReactPlayer>
                    <div id="secondNavContainer">
                        <img src={navbarLogoHomepage} alt="Chicago Riverwalk Live Logo" id="navbarLogoSecondPage" />
                        <p id="riverwalkTextSecondPage">chicagoriverwalklive.com</p>
                    </div>
                </Carousel.Item>
            </Carousel>

        </>

    )
}

export default CarouselReact;