// General imports
import React from "react";
import './MarqueeTwo.css';

const MarqueeTwo = () => {
    return (

        <div id="marqueeTwoText">
            <p>Welcome to Chicago Riverwalk Live!...</p>
            <p>Full website coming soon!...</p>
        </div>

    )
}

export default MarqueeTwo;