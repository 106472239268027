// General imports
import React, { useState } from "react";
import './Home.css';

// Component Imports
import CarouselReact from './CarouselReact';

// Image imports
import homepageLogo from './img/CRLLogoBW.png';

const Home = (props) => {

    return (
        <>
            {/* homepage div (contains carousel with livestreams) */}
            <div id="homePageDiv">
                <CarouselReact />
                <div id="homepageLogoDiv">
                    <img src={homepageLogo} alt="Chicago Riverwalk Live Logo" id="homepageLogo" />
                </div>
            </div>
        </>
    )
}

export default Home;