// General imports
import React from "react";
import './MarqueeFour.css';

const MarqueeFour = () => {
    return (

        <div id="MarqueeFourText">
            <p>Full Website coming soon!</p>
        </div>

    )
}

export default MarqueeFour;